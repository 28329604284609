<template>
  <el-table :data="applications">
    <el-table-column width="55px">
      <template slot-scope="scope">
        <span v-if="scope.row.session_class" class="crown">
          <i class="fas fa-crown" />
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Student">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: scope.row.id }
          }"
        >
          {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="Package Type">
      <template slot-scope="scope">
        {{ scope.row.session_class ? "VIP" : "Free" }}
      </template>
    </el-table-column>
    <el-table-column label="Colleges" prop="counseling_schools_cnt" />
    <el-table-column label="Application Forms">
      <template slot-scope="scope">
        {{ scope.row.applications.length }}
      </template>
    </el-table-column>
    <el-table-column label="Essays" prop="">
      <template slot-scope="scope">
        {{ `${scope.row.essays_completed_cnt}/${scope.row.essays_required_cnt}` }}
      </template>
    </el-table-column>
    <el-table-column label="Meetings" prop="">
      <template slot-scope="scope">
        {{ scope.row.session_class ? scope.row.lesson_logs_cnt : "-" }}
      </template>
    </el-table-column>
    <el-table-column label="Monthly Reports">
      <template slot-scope="scope">
        {{ scope.row.session_class ? scope.row.progress_report_cnt : "-" }}
      </template>
    </el-table-column>
    <el-table-column label="Actions">
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          content="Details"
          placement="top"
          popper-class="tooltipColor"
        >
          <router-link
            :to="
              getApplicationDetail(scope.row.username, scope.row.session_class)
            "
          >
            <i class="fas fa-eye action-icon" />
          </router-link>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { user } from "@ivy-way/material";

export default {
  props: {
    applications: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    user() {
      return user;
    }
  },
  methods: {
    getApplicationDetail(username, session_class) {
      if (session_class) {
        return {
          name: "counselingClassesDetail",
          params: {
            id: session_class.id
          }
        };
      } else {
        return {
          name: "counselingUser",
          params: {
            username
          }
        };
      }
    }
  }
};
</script>

<style scoped>
.crown {
  color: #F0AD4E;
}
</style>
