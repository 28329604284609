<template>
  <div class="container">
    <Breadcrumb />
    <Heading :content="pageTitle"></Heading>
    <hr />
    <ApplicationTable :applications="applications" />
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="$store.getters['applications/pageSize']"
        :current-page="$store.getters['applications/currentPage']"
        @current-change="goToNextPageRoute"
        :total="$store.getters['applications/applicationsTotal']"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ApplicationTable from "@/components/applications/ApplicationTable";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    ApplicationTable
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.applications");
    },
    applications() {
      return this.$store.getters["applications/applications"];
    }
  },
  async created() {
    if (this.$route.query.page) {
      this.$store.dispatch("applications/fetchApplications", {
        page: this.$route.query.page
      });
    } else {
      this.$store.dispatch("applications/fetchApplications", { page: 1 });
    }
  },
  methods: {
    getApplicationDetail(student, sessionClass) {
      if (sessionClass) {
        return {
          name: "counselingClassesDetail",
          params: {
            id: sessionClass.id
          }
        };
      } else {
        return {
          name: "counselingUser",
          params: {
            username: student.userName
          }
        };
      }
    },
    goToNextPageRoute(page) {
      this.$router.push({
        name: "applications",
        query: { page }
      });
    }
  }
};
</script>

<style scoped>
.pagination {
  justify-content: center;
  margin: 20px 0px;
}
</style>
